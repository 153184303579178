<template>
  <div class="container">
    <div class="form-box card cardPad" v-if="detailData.status == 1">
      <div class="messHead">
        <span>操作:</span>
      </div>
      <div class="formInner">
        <el-input
          type="textarea"
          placeholder="操作备注"
          v-model="desc"
          resize="none"
        ></el-input>
        <el-button
          type="primary"
          @click="handleDelivery"
          class="form-btn"
          :disabled="!$hasPermission('DeliveryDetailBtn')"
          >去发货</el-button
        >
      </div>
    </div>
    <div class="orderMess card cardPad">
      <div class="mybtn">
        <el-button @click="$router.go(-1)" icon="el-icon-back"
          >返回上一页</el-button
        >
      </div>
      <div class="messHead">
        <span>订单信息：</span>
      </div>
      <div class="orderMessInner">
        <div class="order-mess-li">
          <span>买家:{{ detailData.userName }}</span>
          <span
            >收货人:{{ detailData.takeDeliveryPerson }} 【{{
              detailData.telephone
            }}】</span
          >
          <span>收货地址:{{ detailData.deliveryAddress }}</span>
          <span>订单备注:{{ detailData.remark }}</span>
        </div>
        <div class="order-mess-li">
          <span>订单编号:{{ detailData.orderNo }}</span>
          <span>下单时间:{{ detailData.orderTime }}</span>
          <span>发货时间:{{ detailData.deliveryDate }}</span>
        </div>
        <div class="order-mess-li">
          <span>订单状态:{{ detailData.orderState }}</span>
          <span>下单类型:{{ detailData.orderTypeTxt }}</span>
          <span>已完成时间:{{ detailData.takeDeliveryDate }}</span>
        </div>
      </div>
    </div>
    <div class="shopMess card cardPad">
      <div class="messHead">
        <span>商品信息：</span>
      </div>
      <el-table
        :data="detailData.orderGoods"
        style="width: 100%"
        class="shopMess_table"
        border
      >
        <el-table-column prop="goodsNo" label="SKU ID"></el-table-column>
        <el-table-column prop="specValue" label="规格信息"></el-table-column>
        <el-table-column label="缩略图">
          <template slot-scope="scope">
            <img
              :src="fileRequest + scope.row.goodsImg"
              alt=""
              class="goodsImg"
              v-if="scope.row.goodsImg != '' && scope.row.goodsImg != null"
            />
            <img
              src="~@/assets/icon/goods-init.png"
              alt=""
              class="goodsImg"
              v-else
            />
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
        <el-table-column prop="num" label="商品数量"></el-table-column>
        <el-table-column prop="price" label="商品价格"></el-table-column>
      </el-table>
    </div>
    <div class="payMess card cardPad">
      <div class="messHead">
        <span>费用信息：</span>
      </div>
      <div class="payMessInner">
        <div class="pay-mess-li">
          <span>商品总价:￥{{ detailData.price }}</span>
          <span>订单金额:￥{{ detailData.price + detailData.fare }}</span>
          <span
            >预计收入:￥{{
              (detailData.price - detailData.ratePrice).toFixed(2)
            }}</span
          >
        </div>
        <div class="pay-mess-li">
          <span>运费价格:￥{{ detailData.fare }}</span>
          <span
            >优惠减免:￥{{
              detailData.discountPrice == "" ? 0 : detailData.discountPrice
            }}</span
          >
          <span>服务商分润:￥{{ detailData.ratePrice }}</span>
        </div>
        <div class="pay-mess-li">
          <span>支付方式:{{ detailData.payTypeTxt }}</span>
          <span>支付时间:{{ detailData.tranDate }}</span>
        </div>
      </div>
    </div>
    <div class="logisticsMess card cardPad" v-if="detailData.deliveryNo != ''">
      <div class="messHead">
        <span>物流信息：</span>
      </div>
      <div class="logisticsMessInner">
        <p>快递单号:{{ detailData.deliveryNo }}</p>
        <p>快递公司:{{ detailData.companyName }}</p>
        <p>签收时间:{{ detailData.takeDeliveryDate }}</p>
      </div>
      <div class="tracesInner">
        <p>物流追踪</p>
        <el-steps direction="vertical" :active="0" finish-status="finish">
          <el-step v-for="(item, index) in logisticsData.traces" :key="index">
            <div slot="description">
              <span class="acceptStation">{{ item.acceptStation }}</span>
              <span class="acceptTime">{{ item.acceptTime }}</span>
            </div>
          </el-step>
        </el-steps>
      </div>
    </div>
    <delivery-dialog
      v-show="deliDialog"
      @deliCancel="deliCancel"
      @deliConfirm="deliConfirm"
      :orderNo="detailData.orderNo"
      :takeDeliveryPerson="detailData.takeDeliveryPerson"
      :telephone="detailData.telephone"
    >
    </delivery-dialog>
  </div>
</template>

<script>
import {
  orderNetListDetail,
  orderNetListDetailyo,
  logisticsDetail,
  consignment,
} from "api/order/orderAPI";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("user");
import deliveryDialog from "./deliveryDialog.vue";
import ModalHelper from "@/utils/dialogScroll";
export default {
  components: {
    deliveryDialog,
  },
  data() {
    return {
      fileRequest: this.$store.getters.fileRequest,
      shopNo: this.$store.getters.shopNo,
      orderNo: this.$route.query.orderNo || null,
      desc: "",
      detailData: {},
      logisticsData: {},
      deliDialog: false,
      ifFresh: false,
    };
  },
  inject: ["reload"],
  created() {
    this.orderNo = this.$route.query.orderNo;
    this.dataInit();
  },
  computed: {
    ...mapGetters(["netOrderPayType", "net_order_type", "net_order_state"]),
  },
  methods: {
    // backPrev() {
    //   this.$router.push({
    //     name: "onlineManage",
    //     params: { ifFresh: this.ifFresh },
    //   });
    // },
    async dataInit() {
      console.log(this.net_order_state);
      console.log(this.net_order_type);

      const res = await orderNetListDetail(this.orderNo);

      const { flag, data, msg } = res.data;
      if (flag) {
        console.log(data);
        this.detailData = data;
        this.detailData.payTypeTxt = this.netOrderPayType.find(
          (n) => n.value == this.detailData.payType
        ).text;
        this.detailData.orderTypeTxt = this.net_order_type.find(
          (n) => n.value == this.detailData.orderType
        ).text;

        this.detailData.orderState = this.net_order_state.find(
          (n) => n.value == this.detailData.status
        ).text;
        if (this.detailData.deliveryNo) {
          this.$nextTick(() => {
            this.logistics(this.detailData.deliveryNo);
          });
        }
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    async logistics(id) {
      const res = await logisticsDetail(id);
      const { flag, data, msg } = res.data;
      if (flag) {
        this.logisticsData = data;
        this.logisticsData.traces = data.traces.reverse();
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    handleDelivery() {
      this.deliDialog = true;
      ModalHelper.afterOpen();
    },
    deliCancel() {
      this.deliDialog = false;
      ModalHelper.beforeClose();
    },
    async deliConfirm(obj) {
      this.deliDialog = false;
      ModalHelper.beforeClose();
      let o = {
        params: {
          shopNo: this.shopNo,
          orderNo: this.detailData.orderNo,
          deliveryNo: obj.senddeliveryNo,
          companyName: obj.ShipperName,
          companyCode: obj.ShipperCode,
          remark: obj.remark,
        },
      };

      const res = await consignment(o);
      const { flag, data, msg } = res.data;
      if (flag) {
        this.dataInit();
        this.reload();
        this.ifFresh = true;
        this.$message({
          type: "success",
          message: "发货成功",
        });
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.messHead {
  line-height: 4.3rem;
  border-bottom: 0.1rem solid #d1d1d1;
  font-size: 1.8rem;
  color: #333333;
  .flex();
  justify-content: flex-start;
  &::before {
    display: inline-block;
    content: "";
    width: 0.5rem;
    height: 1.9rem;
    // background: #1955fa;
    border-radius: 0.3rem;
    margin-right: 0.9rem;
  }
}
.formInner {
  padding-top: 1.4rem;
  .flexCen();
  /deep/ .el-textarea {
    flex: 1;
    textarea {
      height: 12rem;
      border-color: #dcdfe6;
      background: #f8f8f8;
      border-radius: 10px;
      padding: 1rem 0.8rem;
      line-height: 2rem;
      font-size: 1.4rem;
      color: #1955fa;
    }
    ::placeholder {
      line-height: 2rem;
      font-size: 1.4rem;
      color: #1955fa;
    }
  }
  .form-btn {
    width: 10rem;
    height: 3rem;
    padding: 0;
    line-height: 3rem;
    border: 0.1rem solid #1955fa;
    border-radius: 2rem;
    font-size: 1.4rem;
    background: none;
    color: #1955fa;
    margin-left: 1.8rem;
  }
}
.orderMessInner {
  padding: 1.4rem 0 0 0;
  .flexCen();
  .order-mess-li {
    flex: 1;
    background: #f6f6f6;
    border-radius: 0.3rem;
    &:not(:last-child) {
      margin-right: 2.4rem;
    }
    .flex();
    flex-direction: column;
    justify-content: flex-start;
    align-self: normal;
    padding: 1.4rem 1.7rem;
    span {
      display: block;
      width: 100%;
      .word(1.4rem,#333333,2rem,normal,left);
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }
}
.shopMess {
  .messHead {
    border: 0;
  }
  /deep/ .el-table {
    .goodsImg {
      width: 3.5rem;
      height: 3.5rem;
    }
    .has-gutter {
      th,
      tr {
        background: #f8f8f8;
      }
    }
    .el-table__body tr:hover > td {
      background: none !important;
    }
    .cell {
      .word(1.4rem,#000000);
    }
  }
}
.payMessInner {
  padding: 2.2rem 0 8rem 0;
  .flexSt();
  .pay-mess-li {
    min-width: 190px;
    .flex();
    flex-direction: column;
    justify-content: flex-start;
    align-self: normal;
    span {
      display: block;
      width: 100%;
      .word(1.6rem,#333333,2rem,normal,left);
    }
  }
}
.logisticsMessInner {
  padding: 1.8rem 0 0 0;
  > p {
    .word(1.4rem,#000000,2rem);
    margin-bottom: 1.2rem;
  }
}
.tracesInner {
  margin: 2rem 0 0 0;
  p {
    .box(8.3rem,3rem,linear-gradient(180deg, #4519fa 0%, #4a8cff 100%),1.5rem);
    .word(1.6rem,#ffffff,3rem,normal,center);
  }
  /deep/ .el-steps {
    margin: 1.8rem 0 0 0;
    .el-step {
      .el-step__head {
        padding: 0 0 3.6rem 0;
        width: 2.8rem;
        position: relative;
        top: 1.4rem;
      }
      .el-step__line {
        width: 0.1rem;
        top: 0;
        left: 0.4rem;
        background: #e0e1e2;
      }
      .el-step__icon {
        .box(0.9rem,0.9rem,#CFCFCF,50%);
        font-size: 0;
        border: 0;
        .poslt(0,0);
      }
      .is-process .el-step__icon {
        background: #1955fa;
      }
      .is-process .acceptStation {
        color: #1955fa !important;
      }
      .is-process .acceptTime {
        color: #000000 !important;
      }
      .el-step__main {
        padding: 0 0 3.6rem 0;
        .el-step__title {
          display: none;
        }
        .el-step__description div {
          .flexCol();
          span {
            display: block;
            .box(100%,auto,none);
          }
          .acceptStation {
            .word(1.4rem,#666666,2rem);
          }
          .acceptTime {
            .word(1.4rem,#666666,2rem);
          }
        }
      }
    }
  }
}
.mybtn {
  padding-left: 0px !important;
}
</style>
